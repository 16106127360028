import { computed, ref } from 'vue';
import { useStore } from '@/store';

const HUBSPOT_SCRIPT_SRC = '//js.hsforms.net/forms/embed/v2.js';
const HUBSPOT_SCRIPT_ID = 'hubspot_help_form_script';
const HUBSPOT_CONTAINER_ID = 'hubspot_chat_container';
const HUBSPOT_FORM_ID = '5adfcbf0-284c-4a8a-9d3f-dc3fd7699ed0';
const RESET_SCRIPT_DELAY = 1000;

const isChatEmbedded = ref(false);
const isLoading = ref(true);
const isChatOpen = ref(false);

export function useHubspotHelpForm() {
  const store = useStore();

  const user = computed(
    () => store.getters['info/getUsers']?.user || store.getters['info/getUser']
  );

  const accountId = computed(() => store.getters['info/getAccount']?.id);

  function setHubspotHiddenInputs(form) {
    const inputEmail = form.querySelector('input[name="email"]');
    const inputAccountId = form.querySelector('input[name="account_id"]');

    inputEmail.value = user.value.email;
    inputEmail.blur();

    if (accountId?.value) {
      inputAccountId.value = accountId.value;
      inputAccountId.blur();
    }
  }

  function resetHubspotScript() {
    setTimeout(() => {
      document.getElementById(HUBSPOT_SCRIPT_ID).remove();
      document.getElementById(HUBSPOT_CONTAINER_ID).innerHTML = '';

      isLoading.value = true;
    }, RESET_SCRIPT_DELAY);
  }

  function startHubspotChat() {
    if (isChatEmbedded.value) return;

    isLoading.value = true;

    window.hbspt.forms.create({
      portalId: '23756673',
      target: `#${HUBSPOT_CONTAINER_ID}`,
      formId: HUBSPOT_FORM_ID,
      onFormReady($form) {
        const form = $form[0];

        setHubspotHiddenInputs(form);

        isLoading.value = false;
      },
      onFormSubmit() {
        isChatEmbedded.value = false;
      },
    });

    isChatEmbedded.value = true;
  }

  function addHubspotScript() {
    const script = document.createElement('script');

    script.setAttribute('src', HUBSPOT_SCRIPT_SRC);
    script.setAttribute('id', HUBSPOT_SCRIPT_ID);
    script.onload = () => {
      startHubspotChat();
    };

    document.head.appendChild(script);
  }

  function checkIfHubspotScriptExists() {
    return document.getElementById(HUBSPOT_SCRIPT_ID);
  }

  function closeHubspotHelpForm() {
    isChatOpen.value = false;

    if (!isChatEmbedded.value) {
      resetHubspotScript();
    }
  }

  function openHubspotHelpForm() {
    isChatOpen.value = true;
    if (checkIfHubspotScriptExists()) {
      startHubspotChat();
    } else {
      addHubspotScript();
    }
  }

  return { closeHubspotHelpForm, openHubspotHelpForm, isChatOpen, HUBSPOT_CONTAINER_ID, isLoading };
}
